import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { urlEncode } from '../../assets/js/functions'
import '../../assets/styles/app.scss'

export default function Home({className, title}) {

  return (
    <section>
      <Helmet>
        <body className='home'/>
      </Helmet>
      <Link to={{pathname: urlEncode(title, 240)}} className='link'>
        <div className={className}>
            <div className='title'>
                <h1>{title}</h1>
            </div>
        </div>
      </Link>
    </section>
  )
}
