import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './assets/styles/base.scss'
import './assets/styles/variable.scss'
import { BrowserRouter } from "react-router-dom";
import Header from './layouts/Header';

function Index() {

  const [current, setCurrent] = useState(null)

  useEffect(() => {}, [current])

  function _handleClick(item){
    setCurrent(item)
  }

  return (
    <BrowserRouter>
      <Header/>
      <App change={(val) => _handleClick(val.section_id)}/>
    </BrowserRouter>
  )
}

export default ReactDOM.render(<Index/>, document.getElementById('root'));