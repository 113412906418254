import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { useHistory } from 'react-router-dom'
import { getTerm } from '../../api/api'
import { urlEncode } from '../../assets/js/functions'
import classnames from 'classnames'
import ItemMenu from './ItemMenu'
import './styles.scss'

export default function Menu({title, items, selected}) {

    const history = useHistory()
    const searchTerm               = history.location.pathname.split('-').at(-1) || "240"
    const [parents, setParents]    = useState([])
    const [theme, setTheme]        = useState(null)

    useEffect(() => {
        getTerm(searchTerm).then(
            res => {
                const res_parents = JSON.parse(res.data.result[0].parents)
                setParents(res_parents)
                if(res.data.result[0].color){
                    setTheme(res.data.result[0].color)
                }else{
                    res_parents && res_parents.map(
                        val => {
                            return getTerm(val.split('_')[1]).then(
                                it => {
                                    if (it.data.result[0] && it.data.result[0].color) setTheme(it.data.result[0].color)
                                }
                            )
                        }
                    )
                }
            }
          )
    }, [history.location.pathname])

    function _handleClick(val){
        history.replace({pathname: urlEncode(title, 240)})
        selected(val)
    }

    return (
        <>
            {theme &&   <Helmet>
                            <body className={classnames(theme, "terms")}/>
                        </Helmet>
            }
            <div className='menu'>
                <span className='title' onClick={() => _handleClick(0)}>{title}</span>
                <div className='content'>
                    <ul className='items'>
                        {items && parents && !!items.length && items.map(
                            (item, i) => {
                                const isOpen = parents.indexOf("ods1_"+item.section_id) > -1
                                if (!item.isLast) return <ItemMenu key={i} isOpen={isOpen} active={isOpen} parents={parents} item={item} selectedItem={(val, closeMenu) => selected(val, true)}/>
                                return null
                            }
                        )}
                    </ul>
                </div>
            </div>
        </>
    )
}
