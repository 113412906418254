import React, { useEffect, useState } from 'react'
import { getBibliograpyData, getPublications } from '../../api/api'
import { FiExternalLink } from 'react-icons/fi'

export default function Bibliograpy({data, publication}) {

    const [publications, setPublications]       = useState([])

    useEffect(() => {
        JSON.parse(data) && getBibliograpyData(JSON.parse(data).join(',')).then(
            res => {
                res.data.result && res.data.result.map(
                    pubData => {
                        JSON.parse(pubData.publications_data) && getPublications(JSON.parse(pubData.publications_data).join(','), 'section_id').then(
                                pub => setPublications(publications => [...publications, pub.data.result[0]])
                            )
                            return null
                        }
                )
            }
        )
    }, [])
    if (publication) return (
        <div className='bibl-items'>
            {
                !!publications.length && publications.map(
                    (p, i) => {
                        if (p) return  <span className='bibl-item' key={i}>
                                            { p.authors && <span className='authors'> { p.authors.replaceAll(' |', ';') }. </span> }
                                            {
                                                !!p.title ?
                                                    !!p.url_data?
                                                    <span className='title title-link'> <a href={JSON.parse(p.url_data)[0].iri} target='_blank'>"{ p.title }"</a>. </span>
                                                    :<span className='title'> "{ p.title }". </span>
                                                    : null
                                            }
                                            { p.editor && <span className='editor'> { p.editor }. </span> }
                                            { p.magazine && <span className='magazine'> { p.magazine }. </span> }
                                            { p.serie && <span className='serie'> { p.serie }. </span> }
                                            { p.physical_description && <span className='physical_description'> { p.physical_description }. </span> }
                                            { p.place && <span className='place'> { p.place }. </span> }
                                            { p.publication_date && <span className='publication_date'> { p.publication_date.substring(0, 10) } </span> }
                                            { !!p.url_data && <a href={JSON.parse(p.url_data)[0].iri} target='_blank'><FiExternalLink color='#ff5722'/></a> }
                                        </span>
                        return null
                    }
                )
            }
        </div>
    )
    return (
        <div className='bibl-items'>
            {
                !!publications.length && publications.map(
                    (p, i) => {
                        return  <span className='bibl-item' key={i}>
                                    { p.authors && <span className='author'> { p.authors.replaceAll(' |', ';') }. </span> }
                                    {
                                        !!p.title ?
                                            !!p.url_data?
                                            <span className='title title-link'> <a href={JSON.parse(p.url_data)[0].iri} target='_blank'>"{ p.title }"</a>. </span>
                                            :<span className='title'> "{ p.title }". </span>
                                            : null
                                    }
                                    { p.editor && <span className='editor'> { p.editor }. </span> }
                                    { p.magazine && <span className='magazine'> { p.magazine }. </span> }
                                    { p.serie && <span className='serie'> { p.serie }. </span> }
                                    { p.physical_description && <span className='physical_description'> { p.physical_description }. </span> }
                                    { p.place && <span className='place'> { p.place }. </span> }
                                    { p.publication_date && <span className='publication_date'> { p.publication_date.substring(0, 4) } </span> }
                                    { !!p.url_data && <a href={JSON.parse(p.url_data)[0].iri} target='_blank'><FiExternalLink color='#ff5722'/></a> }
                                </span>
                        return null
                    }
                )
            }
        </div>
    )
}