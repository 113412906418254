import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getTerms } from '../../../api/api'
import { urlEncode } from '../../../assets/js/functions'
import { HiPlus, HiMinus } from 'react-icons/hi'
import { Collapse } from 'react-bootstrap'
import '../styles.scss'

export default function ItemsLevel({item, subQuery}) {

    const [childrens, setChildrens] = useState([])
    const [open, setOpen] = useState(false)

    useEffect(() => {
        getTerms("ods1_"+item.section_id, subQuery).then(
            res => setChildrens(res.data.result)
        )
    }, [])

  return (
    <div className='menu last_level'>
        <div    onClick={() => setOpen(!open)}
                aria-controls="last-level-item"
                aria-expanded={open}
                className='content'>
            <span className='title'>{item.title}{ open ? <HiMinus/> : <HiPlus/> }</span>
        </div>
        <Collapse in={open}>
            <div id="last-level-item">
                {!!childrens.length ? childrens.map(
                        (res, i) => { return <span key={i} className='item'>
                                            <Link to={urlEncode(res.title, res.section_id)}>{res.title}</Link>
                                        </span> }
                    ) : null
                }
            </div>
        </Collapse>
    </div>
  )
}
