import React, { useEffect, useState } from 'react'
import { domain, getAudiovisual, getFragment } from '../../api/api'
import { translate } from '../../assets/js/functions'
import { FaTimes } from 'react-icons/fa'
import Modal from 'react-modal'
import './styles.scss'
import { Container, Row } from 'react-bootstrap'
import { ItemVideo } from './ItemVideo'
import Loader from '../Loader'
import { useHistory } from 'react-router-dom'

export default function VideoGallery({item}) {

    const history = useHistory()
    const [video, setVideo] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)
    const [audiovisuals, setAudiovisuals] = useState([])
    const [currentAudiovisual, setCurrentAudiovisual] = useState(null)
    const parameters    = history.location.search ? {open: history.location.search.split('?open=')[1].split('&type=')[0]} : null

    useEffect(() => {
        Modal.setAppElement('#root')
        item && getFragment(item.indexation).then( res => setVideo(res.data.result))
        item && showMore()
        openData()
    }, [item.section_id])

    function openData(){
        if (parameters && parameters.open === 'learnMore'){
            setModalOpen(true)
        }
    }

    function showMore(){
        if (item.audiovisuals){
           setAudiovisuals([])
           JSON.parse(item.audiovisuals).sort().map(
              (av, i) => {
                    return getAudiovisual(av).then(res => {
                        setAudiovisuals(audiovisuals => [...audiovisuals, res.data.result])
                        if (i === 0) setCurrentAudiovisual(res.data.result)
                    })
              }
          )
        }
    }

    function modalParams(val){
        if (val) history.replace({search: '?open=learnMore'})
        else history.replace({search: ''})
        setModalOpen(val)
    }

    return (
        <>
            <Container className='contentVideo'>
                {
                    video && (
                        video.video_url ?
                            <video controlsList="nofullscreen" controls poster={domain+video.posterframe_url}>
                                <source src={domain+video.video_url}/>
                            </video>
                        :   <Loader/>
                        )
                    }
                {
                    currentAudiovisual && currentAudiovisual.informant[0] &&
                    <>
                        <span className='informant'>
                            <span className='name'>{currentAudiovisual.informant[0].name}</span>&nbsp;
                            <span className='surname'>{currentAudiovisual.informant[0].surname}</span>
                            <span className='description'>{currentAudiovisual.title.split('/')[2]}</span>
                        </span>
                    </>
                }
                {
                    item.audiovisuals &&
                    <div className='learnMore'>
                        <span className='btn' onClick={() => modalParams(true)}>
                            {translate('Saber-ne més')}
                        </span>
                    </div>
                }
            </Container>
            <Modal
                isOpen={modalOpen}
                onRequestClose={() => setModalOpen(!modalOpen)}
                portalClassName={"modalPortal"+(modalOpen ? ' open' : '')}
                overlayClassName="modalOverlay"
                className='modalBody'>
                    <FaTimes className='closeModal' onClick={() => modalParams(false)}/>
                        {
                            currentAudiovisual ?
                            <>
                                <div className='video'>
                                    <video controlsList="nofullscreen" controls key={currentAudiovisual.image_url} poster={domain+currentAudiovisual.image_url}>
                                        <source src={domain+currentAudiovisual.image_url.replace('posterframe', '404').replace('jpg', 'mp4')}/>
                                    </video>
                                </div>
                                <span className='description'>
                                    {currentAudiovisual.title}
                                </span>
                            </> : <Loader/>
                        }
                    <Row>
                        {item && audiovisuals ? audiovisuals.map(
                            (res, i) => {
                                return  <ItemVideo
                                            key={i}
                                            col={2}
                                            item={res}
                                            active={currentAudiovisual === res}
                                            className="itemColumn"
                                            thumbClassName="thumbnail"
                                            current={(val) => setCurrentAudiovisual(val)}/>
                            }
                        ) : <Loader/>}
                    </Row>
            </Modal>
        </>
    )
}