import React, { useState } from 'react'
import ReactPageScroller from "react-page-scroller"
import { Route, Switch, useHistory } from 'react-router-dom';
import './assets/styles/app.scss'
import Home from './pages/Home';
import Terms from './pages/Terms';
import { Col, Row } from 'react-bootstrap';
import { getCurrentLanguage, translate, urlEncode } from './assets/js/functions';

export default function App({change}){

  const [currentPage, setCurrentPage] = useState(0)
  const title = translate("Què és la sostenibilitat?")
  let history = useHistory();

  function handlePageChange(number){
    setCurrentPage(number);
  }

  function handleBeforePageChange(number){
    if (currentPage === 0 && number === 1){
      history.replace({ pathname: urlEncode(title, 240) })
      setCurrentPage(2)
    }
    if (currentPage === 2 && number === 1){
      history.replace({ pathname: '/' })
      setCurrentPage(0)
    }
  }

  return (
      <div className='content content-app'>
        <Switch>
          <Route path='/' render={ _ =>
              <ReactPageScroller
                pageOnChange={(val) => handlePageChange(val)}
                animationTimer={500}
                onBeforePageScroll={(val) => handleBeforePageChange(val)}
                containerWidth={'100%'}
                containerHeight={'100vh'}
                blockScrollUp={true}
                blockScrollDown={true}
                customPageNumber={(history.location.pathname === '/'+getCurrentLanguage()) || (history.location.pathname === '/') ? 0 : 2}>
                <Home
                  currentPage={0}
                  className='first'
                  title={title}/>
                <section className='transitionColors'>
                  <Row>
                    <Col xs={3}><div className='bg1'></div></Col>
                    <Col xs={3}><div className='bg2'></div></Col>
                    <Col xs={3}><div className='bg3'></div></Col>
                    <Col xs={3}><div className='bg4'></div></Col>
                  </Row>
                </section>
                <Terms selected={(item) => change(item)}/>
              </ReactPageScroller>
          }/>
          <Route
            path={'*'}
            render={_ => <Home currentPage={0} handlePage={(val) => handlePageChange(val)} className='first'/>}/>
        </Switch>
      </div>
  )
}

