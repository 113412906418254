import React, { useEffect, useState } from 'react'
import { HiPlus, HiMinus } from 'react-icons/hi'
import classnames from 'classnames'
import { Accordion, Collapse } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { urlEncode } from '../../../assets/js/functions'

export default function ItemMenu({item, selectedItem, isOpen, parents, active}) {

  const [open, setOpen]     = useState(isOpen)
  const history = useHistory()

  useEffect(() => {
    setOpen(parseInt(history.location.pathname.split('-').at(-1)) === item.section_id)
  }, [history.location.pathname])

  function selected(item, closeMenu = false){
    history.replace({pathname: urlEncode(item.title, item.section_id)})
    selectedItem(item, closeMenu)
    setOpen(!open)
  }

  return (
        <ul className={classnames('items ', item.color)}>{getContent(item)}</ul>
  )

  function getContent(item){
    if (item.panel_typology === 'galeria') return null
    return(
      <>
        <span className={classnames({active: (parseInt(history.location.pathname.split('-').at(-1)) === item.section_id) || active})} data-toggle="collapse" onClick={() => selected(item, true)}>
            <span>{item.title.replace('SOSTENIBILITAT ', '')}</span>
            {["terme nivell 2 - galeria", "terme nivell 3"].indexOf(item.panel_typology) < 0 ? item.children ? isOpen || open ? <HiMinus/> : <HiPlus/> : null : null}
        </span>
        {item.children ?
          <Collapse in={open || isOpen}>
            {getChildrenContent(item)}
          </Collapse>
        : null
        }
      </>
    )
  }

  function getChildrenContent(item){
    if (!item.children) return null
    return(
        <ul className={classnames('items', item.panel_typology.replaceAll(' ', '-'))}>
          {item.children && parents && !!item.children.length && item.children.map(
            (res, i) => {
              const isOpen = parents.indexOf("ods1_"+res.section_id) > -1
              if (!res.isLast) return <ItemMenu key={i} isOpen={isOpen} active={isOpen} parents={parents} item={res} selectedItem={(res, closeMenu) => selected(res, closeMenu)}/>
              return null
            }
          )}
        </ul>
    )
  }

}
