import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { useHistory } from 'react-router-dom'
import { getAllTerms, getTerm } from '../../api/api'
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai'
import { createObject, translate } from '../../assets/js/functions'
import '../../assets/styles/terms.scss'
import Menu from '../../components/Menu'
import classnames from 'classnames'
import Abstract from '../Abstract'
import Loader from '../../components/Loader'
import Footer from '../../layouts/Footer'

export default function Terms({selected}) {

  const [terms, setTerms]               = useState([])
  const [currentTerm, setCurrentTerm]   = useState(null)
  const history                         = useHistory()
  const [menuOpen, setMenuOpen]         = useState(false)
  const [loading, setLoading]           = useState(true)
  const searchTerm                      = history.location.pathname.split('-').at(-1) || "240"

  useEffect(() => {
    getFirstTerm()
    currentTerm && selected(currentTerm)
  }, [history.location])

  function getFirstTerm(term = null){
    getTerm(term || searchTerm).then(
      res => {
        res.data.result[0] ? setCurrentTerm(res.data.result[0]) : setLoading(false)
      }
    )
    termsData().then( res => setTerms(res) )
  }

  function changeTerm(item, closeMenu){
    item === 0 ? getFirstTerm() : setCurrentTerm(item)
    if (closeMenu) setMenuOpen(false)
  }

  return (
    <div className='term-container'>
      <Helmet>
        {currentTerm ? <title>{translate('Sostenibilitat')} - {currentTerm.title}</title> : <Loader/>}
        <body className='terms'/>
      </Helmet>
      <div className={classnames({open: menuOpen}, "terms")}>
        {menuOpen ? <AiOutlineClose className='sidemenu' onClick={() => setMenuOpen(false)}/> : <AiOutlineMenu className='sidemenu' onClick={() => setMenuOpen(true)}/>}
        <aside>
          {terms && !!terms.length ? <Menu title={translate("Què és la sostenibilitat?")} items={terms} selected={(val, closeMenu) => changeTerm(val, closeMenu)}/> : <Loader/>}
        </aside>
        <main>
          {currentTerm ? <Abstract item={currentTerm}/> : loading ? <Loader/> : <h4>{translate("No s'ha trobat la pàgina")}</h4>}
        </main>
      </div>
      <Footer/>
    </div>
  )
}


export async function termsData(){
  const firstParent  = "ods1_240"
  const tree = []

  return await getAllTerms().then(
    (res, i) => {
      res.data.result && res.data.result.map(
        item => {
          if (JSON.parse(item.parents)[0] === firstParent ){
            tree.push(createObject(item, res.data.result))
          }
          return null
        }
      )
      return tree
    }
  );
}