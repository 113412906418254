import React from 'react'
import { domain } from '../../api/api'
import { Col } from 'react-bootstrap'
import { GrGallery } from 'react-icons/gr'
import { AiOutlineFileText } from 'react-icons/ai'
import { imgPath } from '../../assets/js/functions'
import { LazyImage } from 'react-lazy-media'
import Loader from '../../assets/images/loader.gif'

export default function ItemGallery({item, selectedItem, isGallery, isPublication, title}) {

    let url_img = isPublication ? domain+imgPath(item.image, true) : domain+imgPath(item.identifying_images_data)+item.identifying_images

    return (
        (item.identifying_images && item.identifying_images_data) || item.image ?
            <Col md={6} xl={3} className={'galleryItem'} onClick={() => selectedItem(item)}>
                {isGallery ? <GrGallery/> : null}
                {isPublication ? <AiOutlineFileText/> : null}
                <LazyImage lqip={Loader} src={url_img} alt={item.name || item.title}/>
                <span className='name'>{item.name || item.title || title}</span>
            </Col>
        : null
    )
}
