import React, { useEffect, useState } from 'react'
import './reference.scss'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { urlEncode } from '../../assets/js/functions'
import { getTerm } from '../../api/api'

export default function Reference({attr, name, theme}) {

    const [tagId, setTagId]             = useState(null)
    const [targetItem, setTargetItem]   = useState([])
    const [obj, setObj]                 = useState(null)

    useEffect(() => {
        let obj = name.children[0]
        const item = JSON.parse(attr['data-data'].replaceAll("'", '"'))[0]

        if (!obj.data) obj = name.children[0].children[0]

        if (item){
            setTagId(item.section_id)
            getTerm(item.section_id).then(
                res => setTargetItem(res.data.result[0])
            )
        }
        setObj(obj)
    }, [attr, name])

    return (
        obj && targetItem ?
        <Link to={{pathname: urlEncode(targetItem.title, targetItem.section_id)}} key={attr['id']} className={classNames([{disabled: !tagId, themeReference: obj.data && tagId}, theme || attr.className.replace('reference ', '')])}>
            {obj.data}
        </Link>
        : null
    )
}
