import { domain, objPath } from "../../api/api.js";

export default function JsonDump(props) {
        return <pre style={{"background-color": "#fff",  "border": "2px solid black"}}>{JSON.stringify(props.data, null, 4)}</pre>
}

export function getClassName(item){
    return item.props.className ? "-"+item.props.className : "";
}

export function language(){
    return window.location.search.split('id=')[1]
}

export function translate(text){
    const tranlates = require("../translates.json") ? require("../translates.json") : null;
    const lang      = getCurrentLanguage();


    switch(lang){
        case "ca":
            return tranlates[lang][text] || text;
        case "es":
            return tranlates[lang][text] || text;
        case "en":
            return tranlates[lang][text] || text;
        case "fr":
            return tranlates[lang][text] || text;
        default:
            return text;
    }
}

export function getCurrentLocationByLanguage(lang){
    return "/"+window.location.pathname;
}

export function getCurrentLanguage(){
    const lang = window.location.pathname.split('/')[1];
    let validLang = false;
    getLanguages().map(
        res => res === lang ? validLang = true : false
    );
    if(validLang) return lang;
    return getLanguages()[0];
}

export function getLanguages(){
    return [
        'ca',
        'es',
        // 'en',
        // 'fr'
    ];
}

export function getApiCurrentLanguage(){
    switch(getCurrentLanguage()){
        case 'ca': return 'cat';
        case 'es': return 'spa';
        case 'en': return 'eng';
        case 'fr': return 'fra';
        default: return 'spa';
    }
}

export function isSafari(){
    if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {return true}
    return false;
}

export function languageRoute(lang, current, route = ''){
    if(lang){
        if (current) return window.location.pathname.replace(getCurrentLanguage(), lang)+window.location.hash
        return window.location.host+"/"+lang
    }
    return window.location.host+route;
}

export function getPath(){
    const location = window.location;
    let path = location.pathname+location.search+location.hash;
    return path.replace(languageRoute(''), '');
}

export function isValidLanguage(){
    const lang = window.location.pathname.split('/')[1];
    let validLang = false;
    getLanguages().map(
        res => res === lang ? validLang = true : null
    );
    return validLang;
}

export function getUrlByLanguage(url){
    if(url.split('/')[1] === getCurrentLanguage()){
        return url;
    }
    return "/"+getCurrentLanguage()+url;
}

export function cleanArray(items){
    var filtered = items.filter(function(x) {
        return x !== undefined;
   });
    return [...new Set(filtered.map(e => JSON.stringify(e)))].map(e => JSON.parse(e))
}

export function pdfReader(pdf, action = '_blank'){
    window.open(domain+pdf, action);
}

export function getImage(img){
    return domain+img+"?"+new Date().getTime()
}

export const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}


export function createObject(item, items){
    item.children   = item.children ? getChildren(item.children, items) : null
    item.isLast          = item.parents && JSON.parse(item.parents).length > 3 ? true : false
    return item
  }

  function getChildren(item, listItems = []){
    let ids = []
    let items = []

    JSON.parse(item).map(
      res => ids.push(res.section_id)
    )

    ids.map(
      res => {
        listItems.map(
          listItem => {
            if (listItem.section_id+"" === res){ items.push(createObject(listItem, listItems)) }
            return null
          }
        )
        return null
      }
    )

    return items
  }

  export function imgPath(id, noSubfolder = false){
    if (noSubfolder) return '/dedalo/media/image/1.5MB/'+id
    if (!id) return 0
    let path = 0
    id = JSON.parse(id)[0]
    if (id.length > 3){
        if (id.length > 4){
            return objPath+id[0]+id[1]+"000"+'/'
        }
        path = id[0]+"000"
    }
    return objPath+path+'/'
}

export function urlEncode(url, id = ''){
    url+=' '+id
    return '/'+getCurrentLanguage()+'/'+encodeURI(url.replaceAll(/\?/g, '').replaceAll(/\¿/g, '').replaceAll(' ', '-').normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase())
}