import React, { useEffect } from 'react'
import '../../assets/styles/app.scss'
import parse, { attributesToProps } from 'html-react-parser';
import Reference from '../../components/Reference'
import VideoGallery from '../../components/VideoGallery';
import Gallery from '../../components/Gallery';
import ItemsLevel from '../../components/Menu/ItemsLevel';
import classnames from 'classnames';

export default function Abstract({item}) {

  useEffect(() => {}, [item.section_id])

  return (
    <div className='abstract'>
        {
          item &&
          <div className='content'>
              { item.section_id !== 240 ? <h3 className='contentTitle'>{item.title}</h3> : null}
              { item.body ? <span className={classnames({level_3: item.panel_typology === 'terme nivell 3'}, 'abstractText')}>{ reference(item.body, item.color) }</span> :
                item.indexation ?
                  <>
                    <VideoGallery item={item}/>
                    { item.panel_typology === 'terme nivell 2 - galeria' ? <Gallery key={item.section_id} item={item}/> : null }
                  </>
                : item.body === "" && <Gallery key={item.section_id+1} item={item}/>
              }
              {item.panel_typology === 'terme nivell 2' && item.children ? <ItemsLevel item={item} subQuery={'AND panel_typology="terme nivell 3"'}/> : null}
          </div>
        }
    </div>
  )
}

function reference(html, theme){
  try{
      const options = {
          replace: domNode => {
            if (domNode.attribs && domNode.name === 'reference') {
              const props = attributesToProps(domNode.attribs);
              return (<Reference
                  theme={theme || null}
                  attr={props}
                  name={domNode} />)
            }
          }
        };
      return parse(html, options);
  }catch(e){
      return null;
  }
}
