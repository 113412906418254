import React from "react"
import { Col } from "react-bootstrap"
import classnames from 'classnames'
import { AiFillPlayCircle } from 'react-icons/ai'
import { domain } from "../../api/api"
import { LazyImage } from 'react-lazy-media'

export function ItemVideo({item, col, current, className, thumbClassName, active}){

    return(
        <Col    xs={4} lg={col}
                className={classnames(className, {active: active})}
                onClick={ _ => current(item)}>
            <div className={thumbClassName}>
                <AiFillPlayCircle color='#fff'/>
                <LazyImage src={domain+item.image_url} alt='posterframe'/>
            </div>
        </Col>
    )
}