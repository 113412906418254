import axios from "axios";
import { getApiCurrentLanguage } from "../assets/js/functions";

export const domain     = "https://fons.museuvidarural.cat";
export const webDomain  = "https://museuvidarural.cat"
const URL_SERVER        = domain+'/dedalo/lib/dedalo/publication/server_api/v1/json';
const db_name           = 'web_sostenibilitat';
const code              = '74fDg821Qdwpof7Dhk!2$';
const language          = getApiCurrentLanguage();
export const objPath    = '/dedalo/media/image/1.5MB/';

export async function getTerms(id, subQuery = ""){
    return await axios.get(
        URL_SERVER+
        "/records"+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&table=exhibitions"+
        "&sql_filter="+encodeURI("parents like '[\""+id+"\"%' and title not like ''"+subQuery)+
        "&order=norder"+
        "&limit=0"
    );
}

export async function getTerm(id, order =  "norder"){
    let url = URL_SERVER+
    "/records"+
    "?code="+code+
    "&db_name="+db_name+
    "&lang=lg-"+language+
    "&table=exhibitions"+
    "&section_id="+id+
    "&limit=0"

    if (order) url += "&order="+order

    return await axios.get(url);
}

export async function getAllTerms(inQuery = ""){
    if(inQuery) inQuery = " AND section_id IN "+inQuery
    return await axios.get(
        URL_SERVER+
        "/records"+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&table=exhibitions"+
        '&sql_filter=parents IS NOT NULL AND title NOT LIKE ""'+inQuery+
        "&order=norder"+
        "&limit=0"
    );
}

export async function getFragment(indexLocator){
    return await axios.get(
        URL_SERVER+
        "/fragment_from_index_locator"+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&index_locator="+encodeURI(indexLocator)
        );
}

export async function getAudiovisual(av_id){
    return await axios.get(
        URL_SERVER+
        "/full_reel"+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&av_section_id="+av_id
    )
}

export async function getObjects(obj_id){
    return await axios.get(
        URL_SERVER+
        "/records"+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&table=objects"+
        "&order=title"+
        "&section_id="+obj_id
    )
}

export async function getImages(obj_img){
    return await axios.get(
        URL_SERVER+
        "/records"+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&table=pictures"+
        "&order=section_id"+
        "&section_id="+obj_img
    )
}

export async function getPublications(obj_id, order){

    var query = URL_SERVER+
    "/records"+
    "?code="+code+
    "&db_name="+db_name+
    "&lang=lg-"+language+
    "&table=publications"+
    "&order=section_id"+
    "&section_id="+obj_id

    if (order) query += "&order="+order

    return await axios.get(query)
}

export async function getParents(id){
    return await axios.get(
        URL_SERVER+
        "/records"+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&table=exhibitions"+
        "&ar_fields=parents"+
        "&section_id="+id
    )
}

export async function getBibliograpyData(id){
    return await axios.get(
        URL_SERVER+
        "/records"+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&table=bibliographic_references"+
        "&section_id="+id
    )
}
