import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa'
import { ImFlickr } from 'react-icons/im'
import { webDomain } from '../../api/api'
import { translate } from '../../assets/js/functions'
import './footer.scss'

export default function Footer() {
  return (
    <footer>
        <Container>
            <Row>
                <Col md={3} className="images">
                    <img src={webDomain+'/wp-content/uploads/2018/06/logo_MVR.png'}/>
                    <a href='https://www.fundaciocarulla.cat/'>
                        <img src={webDomain+'/wp-content/uploads/2019/12/FundacioCarulla-Horitzontal-Negatiu-1T-2000x180px-300x27.png'}/>
                    </a>
                </Col>
                <Col md={7} className="menu">
                    <ul>
                        <li><a target={'_blank'} href={(webDomain+"/about/")}>{translate("Qui som?")}</a></li>
                        <li><a target={'_blank'} href={(webDomain+"/politica-ambiental/")}>{translate("Política ambiental")}</a></li>
                        <li><a target={'_blank'} href={(webDomain+"/politica-de-privacitat/")}>{translate("Política de privacitat")}</a></li>
                        <li><a target={'_blank'} href={(webDomain+"/politica-cookies/")}>{translate("Política de cookies")}</a></li>
                    </ul>
                </Col>
                <Col md={2} className="lastCol">
                    <h4>{translate("Butlletí de notícies")}</h4>
                    <a className='btn button' href={webDomain+'/butlleti-noticies'}>{translate("Subscriu-t'hi")}</a>
                    <div className='social'>
                        <a className='twitter' href='https://twitter.com/museuvidarural'><FaTwitter/></a>
                        <a className='facebook' href='https://www.facebook.com/museuvidarural'><FaFacebookF/></a>
                        <a className='instagram' href='https://www.instagram.com/museuvidarural/'><FaInstagram/></a>
                        <a className='youtube' href='https://www.youtube.com/user/museuvidarural/featured'><FaYoutube/></a>
                        <a className='flickr' href='https://www.flickr.com/photos/museuvidarural/albums'><ImFlickr/></a>
                    </div>
                </Col>
            </Row>
        </Container>
    </footer>
  )
}
