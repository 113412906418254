import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { domain, getImages, getObjects, getParents, getPublications, getTerm } from '../../api/api'
import parse from 'html-react-parser'
import { FaTimes } from 'react-icons/fa'
import {BsChevronCompactLeft, BsChevronCompactRight} from 'react-icons/bs'
import { FiZoomIn, FiZoomOut, FiEye } from 'react-icons/fi'
import { HiPlus, HiMinus } from 'react-icons/hi'
import classnames from 'classnames'
import './styles.scss'
import Modal from 'react-modal'
import ItemGallery from './ItemGallery'
import { imgPath, translate } from '../../assets/js/functions'
import 'react-gallery-carousel/dist/index.css'
import Carousel from 'react-gallery-carousel'
import { useHistory } from 'react-router-dom'
import ReactImageGallery from 'react-image-gallery'
import Bibliograpy from './bibliograpyData'

import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon,
  } from "react-share";


export default function Gallery({item}) {

    const history       = useHistory()
    const parameters    = history.location.search ? {open: history.location.search.split('?open=')[1].split('&type=')[0], type: history.location.search.split('&type=')[1]} : null

    const [objects, setObjects]             = useState([])
    const [images, setImages]               = useState([])
    const [publications, setPublications]   = useState([])
    const [currentTerm, setCurrentTerm]     = useState([])
    const [currentItem, setCurrentItem]     = useState(null)
    const [modalOpen, setModalOpen]         = useState(false)
    const [imageOpen, setImageOpen]         = useState(false)
    const [descOpen, setDescOpen]           = useState(false)
    const [bibOpen, setBibOpen]             = useState(false)
    const [breadcrumbs, setBreadcrumbs]     = useState([])
    const [itemType, setItemType]           = useState(null)

    useEffect(() => {
        Modal.setAppElement('#root')
        item.objects &&
            getObjects(JSON.parse(item.objects).join(',')).then(
                obj => setObjects(obj.data.result)
            )

        item.children && getImagesGallery(item)
        if (item.publications){
            getPublications(JSON.parse(item.publications).join(',')).then(
                res => setPublications(publications => [...publications, res.data.result])
            )
        }
        getParents(item.section_id).then(
            res => getTerm(JSON.parse(res.data.result[0].parents).join(',').replace('ods1_240', '').replaceAll('ods1_', ''), false).then(
                val => {
                    let arr = []
                    val.data.result.map(
                        it => arr.push(it.title)
                    )
                    setBreadcrumbs(arr.reverse())
                    setBreadcrumbs(breadcrumbs => [...breadcrumbs, item.title])
                }
            )
        )
        getOpenData()
    }, [item.section_id])

    function getOpenData(){
        if (parameters){
            switch(parameters.type){
                case 'o':
                    getObjects(parameters.open).then(res => { setCurrentItem(res.data.result[0]); setItemType(parameters.type); setModalOpen(true) })
                    break;
                case 'p':
                    getPublications(parameters.open).then(res => { setCurrentItem(res.data.result[0]); setItemType(parameters.type); setModalOpen(true) })
                    break;
            }
        }
    }

    function getImagesGallery(item){
        let childrens = null
        if (typeof item.children === 'string'){
            childrens = JSON.parse(item.children)
        }else{
            childrens = item.children
        }

        const promise = item.parents && childrens && childrens.map(
            (child, i) => { return getTerm(child.section_id).then(
                    res => {
                        if (res.data.result[0] && res.data.result[0].images){
                            return getImages(JSON.parse(res.data.result[0].images).join(',')).then(
                                img => { return [res.data.result[0], img.data.result]}
                            )
                        }
                    }
                )
            }
        )

        Promise.all(promise).then(
            res => {
                if (res) setImages(res)
            }
        )
    }

    function _handleModal(item, type, term = null){
        setModalOpen(true)
        setItemType(type)
        setImageOpen(false)
        setDescOpen(false)
        setBibOpen(false)
        setCurrentItem(item)
        term && setCurrentTerm(term)
        type !== 'g' && history.replace({pathname: history.location.pathname, search: 'open='+ item.section_id +'&type='+type})
    }

    function _closeModal(){
        setModalOpen(false)
        history.replace({search: ''})
    }

    return (
        <>
            <div className={'gallery'}>
                <Row className={'galleryItems'}>
                    {
                        objects && objects.map(
                            (res, i) => {
                                return <ItemGallery key={i} item={res} selectedItem={val => _handleModal(res, 'o')}/>
                            }
                        )
                    }
                    {
                        images && images.map(
                            (res, i) => { return res ? <ItemGallery key={i} item={res[1][0]} title={res[0].title} isGallery selectedItem={val => _handleModal(res[1], 'g', res[0])}/> : null }
                        )
                    }
                    {
                        publications && publications[0] && publications[0].map(
                            (res, i) => { return res ? <ItemGallery key={i} item={res} isPublication selectedItem={val => _handleModal(res, 'p', res)}/> : null }
                        )
                    }
                </Row>
            </div>
            <Modal
                isOpen={modalOpen}
                contentLabel="Modal gallery"
                portalClassName={classnames({open: modalOpen},"modalPortal", itemType)}
                overlayClassName="modalOverlay"
                className={classnames({imageOpen: imageOpen}, 'modalBody galleryModal')}
                onRequestClose={() => _closeModal()}>
                    <FaTimes className="closeModal" onClick={() => _closeModal()}></FaTimes>
                    { currentItem &&
                        <Container className='itemModal'>
                            { breadcrumbs.length > 2 && <span className='breadcrumbs'>
                                {breadcrumbs.map(
                                    (res, i) => <span className='breadcrumbItem' key={i}>{res}</span>
                                )}
                            </span> }
                            { itemType && getItem(currentItem, itemType) }
                        </Container>
                    }
            </Modal>
        </>
    )

  function getItem(currentItem, type){

    if (type === 'p'){
        return(
            <Row>
                <Col md={imageOpen ? 12 : 6} className="image">
                    <img src={domain+imgPath(currentItem.image, true)} alt={currentItem.name}/>
                    {!imageOpen ? <FiZoomIn onClick={() => setImageOpen(true)}/> : <FiZoomOut onClick={() => setImageOpen(false)}/>}
                </Col>
                <Col md={imageOpen ? 0 : 6} className={classnames({hidden: imageOpen}, "info publication")}>
                    <span className="itemTitle"><strong>{translate('Nom')}: </strong>{ currentItem.title }</span>
                    <span className="itemAuthor"><strong>{translate('Autor')}: </strong>{ currentItem.authors_name } { currentItem.authors_surname }</span>
                    <span className={classnames({open: descOpen}, "itemDesc")}><strong>{translate('Resum')}: </strong>{ parse(currentItem.abstract) }</span>
                    <a  href={JSON.parse(currentItem.pdf_uri)[0].iri}
                        target='_blank'
                        rel='noreferrer'
                        className={classnames({disabled: !JSON.parse(currentItem.pdf_uri)[0].iri}, 'btn btn-success')}>
                            <FiEye/> {translate('Veure publicació')}
                    </a>
                </Col>
            </Row>
        )
    }

    if (type === 'g'){
        const images = currentItem && currentItem.map((item) => ({
            src: domain+imgPath(item.identifying_images_data)+item.identifying_images
        }));
        return(
            <div className={classnames({openDesc: descOpen || bibOpen},'galleryCarousel')}>
                { currentTerm && <h3>{currentTerm.title}</h3> }
                {
                    images && !!images.length &&
                    <Carousel
                        hasDotButtons="bottom"
                        hasThumbnails={false}
                        hasIndexBoard={'topLeft'}
                        leftIcon={<BsChevronCompactLeft style={{color: '#000', fontSize: '2.5em'}}/>}
                        rightIcon={<BsChevronCompactRight style={{color: '#000', fontSize: '2.5em'}}/>}
                        isAutoPlaying
                        hasMediaButton={false}
                        images={images}
                        style={{ height: 500, width: '60%' }}
                        className="carousel" />
                }
                {   currentTerm &&
                    <>
                        <div className="info">
                            <span className='desc'><strong>{translate("Relació amb la sostenibilitat")}: </strong>
                                { descOpen ?  parse(currentTerm.body) : parse(currentTerm.body.split('<br />')[0]) }
                            </span>
                        </div>
                        <button className='btn btn-info' onClick={_ => setDescOpen(!descOpen)}>
                            {!descOpen ? translate("Mostrar més") : translate("Mostrar menys")}
                        </button><br/><br/>
                        <button className='btn btn-info' onClick={ _ => { setBibOpen(!bibOpen); setDescOpen(false) }}>
                            { bibOpen ? <HiMinus/> : <HiPlus/> }{translate('Bibliografia')}
                        </button>
                        { bibOpen ? <Bibliograpy publication data={currentTerm.bibliography_data}/> : null }
                    </>
                }
            </div>
        )
    }
    if (type === 'o'){
        const images = [{original: domain+imgPath(currentItem.identifying_images_data)+currentItem.identifying_images, alt: currentItem.name}]
        return(
            <>
                <Row>
                    <Col md={6} className="image_obj">
                        <ReactImageGallery
                            showNav={false}
                            showPlayButton={false}
                            showThumbnails={false}
                            items={images}/>
                    </Col>
                    <Col md={6} className={classnames("info")}>
                        <span className="itemName"><strong>{translate("Nom")}: </strong>{currentItem.name}</span>
                        <span className={classnames({open: descOpen}, "itemDesc")}><strong>{translate("Relació amb la sostenibilitat")}: </strong>
                            { descOpen ?  parse(currentItem.use_details) : parse(currentItem.use_details.split('<br>')[0]) }
                        </span>
                        { currentItem.use_details.split('<br>')[0]  ? <><span className='itemButton' onClick={() => setDescOpen(!descOpen)}>{ descOpen ? <HiMinus/> : <HiPlus/> }{translate('Informació')}</span><br/></> : null}
                        { currentItem['related_bibliography _data'] ? <><span className='itemButton' onClick={() => setBibOpen(!bibOpen)}>{ bibOpen ? <HiMinus/> : <HiPlus/> }{translate('Bibliografia')}</span><br/></> : null}
                    </Col>
                    { bibOpen && <Bibliograpy data={currentItem['related_bibliography _data']}/> }
                </Row>
                <div className='socialShares'>
                    <FacebookShareButton title={currentItem.name} url={window.location.href}>
                        <FacebookIcon round size={'2.2em'}/>
                    </FacebookShareButton>
                    {/* <LinkedinShareButton title={currentItem.name} summary={parse(currentItem.use_details)} url={window.location.href}>
                        <LinkedinIcon round size={'2.2em'}/>
                    </LinkedinShareButton> */}
                    <TwitterShareButton title={currentItem.name} url={window.location.href} >
                        <TwitterIcon round size={'2.2em'}/>
                    </TwitterShareButton>
                    <WhatsappShareButton title={currentItem.name} url={window.location.href} >
                        <WhatsappIcon round size={'2.2em'}/>
                    </WhatsappShareButton>
                </div>
            </>
        )
    }
  }
}
