import styles from './styles.scss';
import {getCurrentLanguage, getLanguages, translate} from '../../assets/js/functions'
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

export default function Header() {

  const history = useHistory()
  const [museumUrl, setMuseumUrl] = useState('https://museuvidarural.cat/')
  const [lang, setLang]           = useState(getCurrentLanguage())

  useEffect(() => {
    if (getCurrentLanguage() === 'es') setMuseumUrl(museumUrl + 'castellano')
    if (getCurrentLanguage() === 'en') setMuseumUrl(museumUrl + 'english')
    if (getCurrentLanguage() === 'fr') setMuseumUrl(museumUrl + 'francais')
  }, [lang])

  return (
    <header>
      {getCurrentLanguage() &&
        <Row>
            <Col xs={4} className="languages">
              {getLanguages().map(
                (item, i) => {
                          history.location.pathname.replace('/'+getCurrentLanguage(), '/'+item) === '/' && history.replace({pathname: '/'+getCurrentLanguage(), replace: true}) && window.location.reload(false)
                          return <Link to={history.location.pathname.replace('/'+getCurrentLanguage(), '/'+item)} onClick={() => setLang(item)} key={i+lang} className="headerLink">{item}</Link>
                      }
              )}
            </Col>
            <Col xs={8} className="link">
              { museumUrl ? <a className="headerLink" href={museumUrl} target="_blank" rel="noreferrer">{translate("Visita el museu")}</a> : null }
            </Col>
        </Row>
      }
    </header>
  )
}

